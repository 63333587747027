export const PAGE_TYPE = Object.freeze({
  HOME_PAGE: "home-page",
  SECTION_PAGE: "section-page",
  TAG_PAGE: "tag-page",
  SEARCH_PAGE: "search-page",
  STORY_PAGE: "story-page",
  STORY_PUBLIC_PREVIEW_PAGE: "story-public-preview-page",
  STORY_PREVIEW: "story-preview",
  HOME_PREVIEW: "home-preview",
  STATIC_PAGE: "static-page",
  FORM_PAGE: "form-page",
  ABOUT_US_PAGE: "about-us-page",
  EDITORIAL_TEAM_PAGE: "editorial-team-page",
  RSS_PAGE: "rss-page",
  DISCLAIMER_PAGE: "disclaimer-page",
  ADVERTISE_PAGE: "advertise-page",
  TERMS_OF_USE: "terms-of-use-page",
  POLICY_PAGE: "policy-page",
  SERVICES_PAGE: "services-page",
  EDITORIAL_GUIDELINES: "editorial-guidelines",
  LATEST_STORIES_PAGE: "latest-stories-page",
  USER_PAGE: "user-page",
  AUTHOR_PAGE: "author-page",
  LATEST_COLLECTIONS_PAGE: "latest-collections-page",
  BUNDLE_PAGE: "bundle-page",
  EVENT_PAGE: "event-page",
  EVENT_SCHEDULE_PAGE: "event-schedule-page",
  EVENT_ROSTER_PAGE: "event-roster-page",
  EVENT_RESULTS_PAGE: "event-results-page",
  EVENTS_LISTING: "events-listing",
  CONTACT_US: "contact-us",
  USER_SETTINGS: "settings-page",
  RESET_PASSWORD: "reset-password",
  ARCHIVED_ARTICLES: "archived-articles",
  ARCHIVE_LISTING: "archive-listing",
  STORY_PAGE_AMP: "story-page-amp",
  VISUAL_STORY: "visual-story",
  VISUAL_STORY_LISTING: "visual-story-listing",
  INDIA_HUB_HOME: "india-hub-home",
  INDIA_HUB_SECTION: "india-hub-section",
  LEADERBOARD_PAGE: "leaderboard_page",
  LEADERBOARD_POLICY: "leaderboard_policy",
  SUBSCRIPTION_PAGE: "subscription-page",
  CHECKOUT_PAGE: "checkout_page",
  SUCCESS_PAGE: "success_page",
  REFUND_POLICY: "refund_policy",
  PROMOTIONAL_PAGE: "promotional_page",
  INTL_PROMOTIONAL_PAGE: "intl_promotional_page",
  FREE_READS_PAGE: "freereads-page",
  WIDGET: "widget"
});
export const TAG_PAGE_URL_PREFIX = "/tags/";
